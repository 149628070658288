import { Routes, Route } from 'react-router-dom';
import './App.css';
import About from './pages/about/about';
import Enquiry from './pages/enquiry/enquiry';
import Catalogue from './pages/catalogue/catalogue';
import Contact from './pages/contact-us/contact-us';
import Export from './pages/export/export';
import Home from './pages/home/home';
import PageNotFound from './shared/components/page-not-found/page-not-found';
import Privacy from './shared/components/privacy-policy/privacy-policy';
import Terms from './shared/components/terms-conditions/terms-conditions';

const App = () => {
  return (
    <Routes>
      <Route path='*' element={<PageNotFound />}></Route>
      <Route path='/' element={<Home />}></Route>
      <Route path='/about' element={<About />}></Route>
      <Route path='/catalogue' element={<Catalogue />}></Route>
      <Route path='/export' element={<Export />}></Route>
      <Route path='/contact' element={<Contact />}></Route>
      <Route path='/terms-conditions' element={<Terms />}></Route>
      <Route path='/privacy-policy' element={<Privacy />}></Route>
      <Route path='/enquiry' element={<Enquiry />}></Route>
    </Routes>
  );
}

export default App;
