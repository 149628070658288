import Header from "../header/header";
import TC from "../../../assets/headers/TC.jpg";
import Footer from "../footer/footer";
import Navbar from "../top-navigation/top-navigation";

const Terms = () => {

    return <>
        <Navbar />
        <div className="terms-container">
            <div className="terms-wrapper">
                <Header description="Our Terms & Conditions" imageUrl={TC} title="terms & conditions" />
                <p>

                </p>
            </div>
        </div>
        <Footer />
    </>
}

export default Terms;