import "../top-navigation/top-navigation.scss";
import Logo from "../../../assets/logo/vincentWhiteLogo.png";
import { NavLink } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import SideMenu from "../side-menu/sidemenu";
import { useRef } from "react";

const Navbar = () => {

    const sideMenuRef: any = useRef();

    const seeRef = () => {
        sideMenuRef.current.openNav();
      }

    return <>
        <nav>
            <div className="nav-wrapper">
                <div className="logo lineUp">
                    <img src={Logo} alt="Logo" />
                </div>
                <SideMenu ref={sideMenuRef}/>
                <div className="menu" onClick={seeRef}>
                    <FaBars color="white" size={'1.2rem'} />
                </div>
                <ul className="lineUp">
                    <li>
                        <NavLink to="/">Home</NavLink>
                    </li>
                    <li>
                        <NavLink to="/about">About</NavLink>
                    </li>
                    <li>
                        <NavLink to="/catalogue">Catalogue</NavLink>
                    </li>
                    <li>
                        <NavLink to="/export">Export</NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact">Contact Us</NavLink>
                    </li>
                </ul>
            </div>
        </nav>
    </>
}

export default Navbar;