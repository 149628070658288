import React from "react";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import Footer from "../../shared/components/footer/footer";
import Header from "../../shared/components/header/header";
import Navbar from "../../shared/components/top-navigation/top-navigation";
import "../catalogue/catalogue.scss";
import catalogueBg from "../../assets/catalogue/bg.jpg";
import wall from "../../assets/catalogue/Wall_Catalogue.png";
import gvt from "../../assets/catalogue/GVT_Catalogue.png";
import fullbody from "../../assets/catalogue/FBVT_Catalogue.png";
import double from "../../assets/catalogue/DCVT_Catalogue.png";
import parking from "../../assets/catalogue/Parking_Catalogue.png";
import step from "../../assets/catalogue/SR_Catalogue.png";
import porcelain from "../../assets/catalogue/Porcelain_Catalogue.png";
import img1 from '../../assets/about1.jpg';
import img2 from '../../assets/about2.jpg';
import img3 from '../../assets/about3.jpg';
import ImageSlider from "../../shared/components/carousel/carousel";
import image1 from '../../assets/Interior/1.jpg';
import image2 from '../../assets/Interior/2.jpg';
import image3 from '../../assets/Interior/3.jpg';
import image4 from '../../assets/Interior/4.jpg';
import image5 from '../../assets/Interior/5.jpg';
import image6 from '../../assets/Interior/6.jpg';
import image7 from '../../assets/Interior/7.jpg';
import image8 from '../../assets/Interior/8.jpg';
import image9 from '../../assets/Interior/9.jpg';
import image10 from '../../assets/Interior/10.jpg';
import image11 from '../../assets/Interior/11.jpg';
import { FaDownload } from "react-icons/fa";


const Catalogue = () => {

    useDocumentTitle("Catalogue");

    const Catalogues = [
        {
            title: 'Wall Tiles',
            image: `${image1}`
        },
        {
            title: 'GVT/PGVT Tiles',
            image: `${image2}`
        },
        {
            title: 'Porcelain Tiles',
            image: `${image3}`
        },
        {
            title: 'Fullbody Vitrified Tiles',
            image: `${fullbody}`
        },
        {
            title: 'Parking Tiles',
            image: `${parking}`
        },
        {
            title: 'Step Risers Tiles',
            image: `${step}`
        },
        {
            title: 'Double Charged Vitrified Tiles',
            image: `${double}`
        }
    ];
    const sliderImage = [
        { src: image1, caption: 'about 1 caption' },
        { src: image2, caption: 'about 2 caption' },
        { src: image3, caption: 'about 3 caption' },
        { src: image4, caption: 'about 1 caption' },
        { src: image5, caption: 'about 2 caption' },
        { src: image6, caption: 'about 3 caption' },
        { src: image7, caption: 'about 1 caption' },
        { src: image8, caption: 'about 2 caption' },
        { src: image9, caption: 'about 3 caption' },
        { src: image10, caption: 'about 1 caption' },
        { src: image11, caption: 'about 2 caption' },
    ]
    return <>
        <Navbar />
        <div className="catalogue-container">
            <div className="catalogue-wrapper">
                <Header description="Download our catalogue" imageUrl={catalogueBg} title="Catalogue" />
                <ImageSlider images={sliderImage} />
                <div className="catalogues">
                    {Catalogues.map(catalogue => {
                        return <div key={catalogue.title} className="catalogue">
                            <div className="catalogue-image">
                                <img src={catalogue.image} alt={catalogue.title} />
                            </div>
                            <button className="download_button"><FaDownload/></button>
                            <p className="title">{catalogue.title}</p>
                        </div>
                    })}
                </div>
            </div>
        </div>
        <Footer />
    </>
}

export default Catalogue;