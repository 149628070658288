import style from '../check-catalogue/check-catalogue.module.scss';
import { FaArrowAltCircleRight, FaArrowRight } from 'react-icons/fa';
import catalogue from '../../assets/catalogue.jpg';

const CheckCatalogue = () => {

    return <>
        <div className={style.catalogue_container}>
            <div className={style.catalogue_wrapper}>
                <div className={style.content}>
                    <h3 className={style.title}>Explore Our Extensive Catalogue</h3>
                    <p className={style.message}>
                        Browse our comprehensive catalogue to find the perfect tile for your space. Discover a wide range of stylish and durable options in various materials,
                        colors, and patterns. From modern to classic, our catalogue has something for every taste and design requirement. Explore now and get inspired to create
                        your dream space!
                    </p>
                    <button className={`${style.btn} ${style.lineUp}`}>
                        View Catalogues <FaArrowAltCircleRight/>
                    </button>
                </div>
                <img src={catalogue} className={style.catalogue}/>
            </div>
        </div>
    </>
}

export default CheckCatalogue;