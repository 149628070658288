import wall from "../../../assets/tiles/wall_tile.jpg";
import double from "../../../assets/tiles/double_tiles.jpg";
import fullbody from "../../../assets/tiles/fullbody_tiles.jpg";
import gvt from "../../../assets/tiles/gvt_tiles.jpg";
import parking from "../../../assets/tiles/parking_tiles.jpg";
import porcelain from "../../../assets/tiles/porcelain_tiles.webp";
import step from "../../../assets/tiles/step_tiles.jpg";
import "../products/products.scss";

const Products = () => {

    const tiles = [
        {
            title: 'Wall Tiles',
            image: `${wall}`
        },
        {
            title: 'GVT/PGVT Tiles',
            image: `${gvt}`
        },
        {
            title: 'Porcelain Tiles',
            image: `${porcelain}`
        },
        {
            title: 'Fullbody Vitrified Tiles',
            image: `${fullbody}`
        },
        {
            title: 'Parking Tiles',
            image: `${parking}`
        },
        {
            title: 'Step Risers Tiles',
            image: `${step}`
        },
        {
            title: 'Double Charged Vitrified Tiles',
            image: `${double}`
        }
    ];
    return <>
        <div className="product_container">
            <h2>Varieties we offer</h2>
            <div className="product_wrapper">
                {tiles.map(tile => { return <div key={tile.title} className="product">
                    <img src={tile.image} alt=""/>
                    <p>{tile.title}</p>
                </div>})}
            </div>
        </div>
    </>
}

export default Products;