import "../export/export.scss";
import exportImage from "../../assets/exports-image.jpg";
import map from "../../assets/map.png";
import Australia from "../../assets/export/Australia.png";
import Behrain from "../../assets/export/Behrain.png";
import Iraq from "../../assets/export/Iraq.png";
import Italy from "../../assets/export/Italy.png";
import Jordan from "../../assets/export/Jordan.png";
import Kenya from "../../assets/export/Kenya.png";
import Kuwait from "../../assets/export/Kuwait.png";
import NewZealand from "../../assets/export/New-Zealand.png";
import Qatar from "../../assets/export/Qatar.png";
import Poland from "../../assets/export/Poland.png";
import SaudiArabia from "../../assets/export/Saudi-Arabia.png";
import SouthAfrica from "../../assets/export/South-Africa.png";
import Tanzania from "../../assets/export/Tanzania.png";
import UAE from "../../assets/export/UAE.png";
import UK from "../../assets/export/UK.png";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import Navbar from "../../shared/components/top-navigation/top-navigation";
import Footer from "../../shared/components/footer/footer";
import Header from "../../shared/components/header/header";
import exportBg from "../../assets/export1.jpg";
import exportImage1 from "../../assets/export/exportImage1.jpeg";
import exportImage2 from "../../assets/export/exportImage2.jpeg"
import exportImage3 from "../../assets/export/exportImage3.jpeg"
import ImageSlider from "../../shared/components/carousel/carousel";

const Export = () => {
    useDocumentTitle("Export");
    const sliderImage =[
        { src: exportImage1, caption: 'about 1 caption' },
        { src: exportImage2, caption: 'about 2 caption' },
        { src: exportImage3, caption: 'about 3 caption' },
    ]
    return <>
        <Navbar />
        <div className="export-container">
            <div className="export-wrapper">
                <Header description="Our global presence" imageUrl={exportBg} title="export" />
                <div className="export-details">
                    <div className="image">
                        <img src={exportImage} alt="" />
                    </div>
                    <div className="export-content">
                        <h3 className="heading">We Export Globally</h3>
                        <p>
                            At our core, we believe in delivering only the finest quality tiles, and we've made it our mission to share them with the world.
                            With a robust global footprint, our ceramic tiles are making a mark from Europe and the Middle East to the Asia Pacific and Africa, and beyond.
                        </p>
                        <p>
                            We’ve honed processes that ensure seamless buying and importing experiences, fostering relationships built on trust and reliability.
                            Our ears are always open to the voices of our customers and importers, ensuring we meet and exceed expectations.
                        </p>
                        <p>
                            Our export network is a testament to our dedication—a reliable web of qualified buyers and retailers with deep market penetration.
                            This network enables our exceptional products and brand experience to reach customers globally, solidifying our place as a leader in the industry.
                        </p>
                    </div>
                </div>
                <div className="export-images">
                    <div className="heading">
                        <h3>Our manufacturing unit</h3>
                    </div>
                    <ImageSlider images={sliderImage}/> 
                    {/* <div className="factory">
                        <img src={exportImage2} alt="factory" />
                        <img src={exportImage1} alt="factory" />
                        <img src={exportImage3} alt="factory" />
                    </div> */}
                </div>
                <div className="map-container">
                    <span className="title">Our Global Reach</span>
                    <div className="map-image">
                        <img src={map} alt="world map" />
                    </div>
                    <div className="country-flags-container">
                        <div className="country-flags-row">
                            <ul>
                                <li><img src={Australia} alt="country" /><span className="right"> Australia</span></li>
                                <li><img src={Behrain} alt="country" /><span className="right"> Behrain</span></li>
                                <li><img src={Iraq} alt="country" /><span className="right"> Iraq</span></li>
                                <li><img src={Italy} alt="country" /><span className="right"> Italy</span></li>
                            </ul>
                        </div>
                        <div className="country-flags-row">
                            <ul>
                                <li><img src={Jordan} alt="country" /><span className="right"> Jordan </span></li>
                                <li><img src={Kenya} alt="country" /><span className="right"> Kenya</span></li>
                                <li><img src={Kuwait} alt="country" /><span className="right"> Kuwait</span></li>
                                <li><img src={Behrain} alt="country" /><span className="right">Behrain</span></li>
                            </ul>
                        </div>
                        <div className="country-flags-row">
                            <ul>
                                <li><img src={NewZealand} alt="country" /><span className="right"> New Zealand </span></li>
                                <li><img src={Poland} alt="country" /><span className="right"> Poland</span></li>
                                <li><img src={Qatar} alt="country" /><span className="right"> Qatar</span></li>
                                <li><img src={SaudiArabia} alt="country" /><span className="right">Saudi Arabia</span></li>
                            </ul>
                        </div>
                        <div className="country-flags-row">
                            <ul>
                                <li><img src={SouthAfrica} alt="country" /><span className="right">South Africa </span></li>
                                <li><img src={Tanzania} alt="country" /><span className="right"> Tanzania</span></li>
                                <li><img src={UAE} alt="country" /><span className="right">UAE</span></li>
                                <li><img src={UK} alt="country" /><span className="right">UK</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
}

export default Export;