import Header from "../header/header";
import PP from "../../../assets/headers/PP.jpg";
import "./privacy-policy.scss";
import Footer from "../footer/footer";
import Navbar from "../top-navigation/top-navigation";

const Privacy = () => {

    return <>
        <Navbar />
        <div className="privacy-container">
            <div className="privacy-wrapper">
                <Header description="Our Privacy Policy" imageUrl={PP} title="privacy policy" />
            </div>
        </div>
        <Footer/>
    </>
}

export default Privacy;