import videoBg from "../../assets/videoBg.mp4";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import ImageSlider from "../../shared/components/carousel/carousel";
import Footer from "../../shared/components/footer/footer";
import Navbar from "../../shared/components/top-navigation/top-navigation";
import VincentDeals from "../../shared/components/vincent-deals/vincent-deals";
import CheckCatalogue from "../check-catalogue/check-catalogue";
import '../home/home.scss';
import KnowMore from "../know-more/know-more";
import SendMessage from "../send-message/send-message";
import Statistics from "../statistics/statistics";
import img1 from '../../assets/about1.jpg';
import img2 from '../../assets/about2.jpg';
import img3 from '../../assets/about3.jpg';
import ViewAllProjects from "../view-all-projects/view-all-projects";
import image1 from '../../assets/Interior/1.jpg';
import image2 from '../../assets/Interior/2.jpg';
import image3 from '../../assets/Interior/3.jpg';
import image4 from '../../assets/Interior/4.jpg';
import image5 from '../../assets/Interior/5.jpg';
import image6 from '../../assets/Interior/6.jpg';
import image7 from '../../assets/Interior/7.jpg';
import image8 from '../../assets/Interior/8.jpg';
import image9 from '../../assets/Interior/9.jpg';
import image10 from '../../assets/Interior/10.jpg';
import image11 from '../../assets/Interior/11.jpg';

const Home = () => {
    useDocumentTitle("Home");
    const sliderImage = [
        { src: image1, caption: 'about 1 caption' },
        { src: image2, caption: 'about 2 caption' },
        { src: image3, caption: 'about 3 caption' },
        { src: image4, caption: 'about 1 caption' },
        { src: image5, caption: 'about 2 caption' },
        { src: image6, caption: 'about 3 caption' },
        { src: image7, caption: 'about 1 caption' },
        { src: image8, caption: 'about 2 caption' },
        { src: image9, caption: 'about 3 caption' },
        { src: image10, caption: 'about 1 caption' },
        { src: image11, caption: 'about 2 caption' },
    ]

    return <>
        <header className="header">
            <div className="overlay"></div>
            <Navbar />
            <video src={videoBg} loop autoPlay muted />
            <div className="contents">
                <h1 className="lineUp">Welcome to Vincent Ceramica</h1>
                <p className="tagline">we provide complete flooring solution for you</p>
                <button className="btn lineUp">
                    Get Started
                </button>
            </div>
        </header>
        <Statistics />
        <CheckCatalogue />
        <ImageSlider images={sliderImage} />
        {/* <VincentDeals/> */}

        <KnowMore />
        {/* <ViewAllProjects/> */}
        <SendMessage />
        <Footer />
    </>
}

export default Home;