import backgroundImage from "../../../assets/about1.jpg";
import foregroundImage from "../../../assets/about2.jpg";
import Products from "../products/products";
import '../vincent-deals/vincent-deals.scss';

const VincentDeals = () => {
    return <>
        <div className="vincent-deals-container">
            <div className="vincent-deals-wrapper">
                <div className="image-wrapper">
                    <img src={backgroundImage} alt="" className="bg-image" />
                    <div className="fg-image">
                        <img src={foregroundImage} alt="" />
                    </div>
                </div>

                <div className="about-contents">
                    <h2>Vincent Ceramica</h2>
                    <div className="divider"></div>
                    <div className="images">
                        <img src={backgroundImage} alt="" className="bg-image" />
                        <img src={foregroundImage} alt="" className="fg-image" />
                    </div>
                    <p className="detail">
                        We have been in business since 1994 and have been providing high-quality products at low prices to our 
                        customers. Our mission is to make sure that every product we sell is of the highest quality and meets 
                        or exceeds customer expectations.
                    </p><br />
                    <p className="detail">
                        We offer an extensive selection of tiles that can be used for both interior and exterior purposes. We carry 
                        everything from bathroom tiles to kitchen backsplash tiles. For those who are looking for novelty items in 
                        their homes such as wall cladding or flooring options, we also carry those as well.
                    </p><br />
                    <p className="detail">
                        We pride ourselves on being able to provide our customers with the best possible value for their money by 
                        offering competitive pricing on all of our products so they can get exactly what they need without having 
                        to worry about any hidden costs or fees.
                    </p>
                    <div className="divider"></div>
                </div>
            </div>
            <Products />
        </div>
    </>
}
export default VincentDeals;