import { useState, useEffect } from 'react';

const useCountUp = (end: any, duration: any) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        let start = 0;
        const endValue = parseInt(end);
        const incrementTime = Math.abs(Math.floor((duration * 1000) / endValue));

        const timer = setInterval(() => {
            start += 1;
            setCount(start);
            if (start === endValue) {
                clearInterval(timer);
            }
        }, incrementTime);

        return () => clearInterval(timer);
    }, [end, duration]);

    return count;
};

export default useCountUp;