import style from '../know-more/know-more.module.scss';
import { FaArrowAltCircleRight, FaArrowRight } from 'react-icons/fa';
import catalogue from '../../assets/catalogue.jpg';

const KnowMore = () => {

    return <>
        <div className={style.catalogue_container}>
            <div className={style.catalogue_wrapper}>
                <div className={style.content}>
                    <h3 className={style.title}>Discover Our Story and Values</h3>
                    <p className={style.message}>
                        We believe in creating beautiful and durable spaces that inspire and last a lifetime. Our journey began with a passion for design and a commitment
                        to quality. Over the years, we have grown into a trusted provider of top-notch tiles, serving a diverse range of clients and industries
                    </p>
                    <button className={`${style.btn} ${style.lineUp}`}>
                        Know More <FaArrowAltCircleRight />
                    </button>
                </div>
                <img src={catalogue} className={style.catalogue}/>
            </div>
        </div>
    </>
}

export default KnowMore;