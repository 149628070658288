import { FaEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn, FaPhoneAlt } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import logo from '../../../assets/logo/vincentWhiteLogo.png';
import '../footer/footer.scss';

const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    let todaysDate = new Date().getFullYear();

    return <>
        <div className="footer-container">
            <div className="footer-wrapper">
                <div className='mask'></div>
                <div className='call-schedule'>
                    To schedule a meeting, simply give us a ping at <span className='contact'><a href="tel:8591976532">(+91)-8591976532</a></span>.<br />
                    We're looking forward to connecting with you!
                    <div className='button-container'>
                        <NavLink to="/contact" className="contact-button">Contact Us</NavLink>
                    </div>
                </div>
                <div className='footer-contents'>
                    <div className='contact-details'>
                        <div className='logo-container'>
                            <img src={logo} alt='logo' />
                        </div>
                        <address>
                            S-37, Siromani Business Center, <br />
                            B/H Real Plaza, Morbi - 2, Gujarat
                        </address>
                        <p className='email'>
                            <span className='social-icon'>
                                <FaEnvelope />
                            </span>
                            <a href="mailto:info@vincentceramica.com">info@vincentceramica.com</a>
                        </p>
                        <p className='mobile'>
                            <span className='social-icon'>
                                <FaPhoneAlt />
                            </span>
                            <a href="tel:9711741117">+91-9711741117 ( Anand Patel )</a>
                        </p>
                    </div>
                    <div className='quick-links'>
                        <label>Quick Links</label>
                        <ul>
                            <li>
                                <NavLink to="/" onClick={scrollToTop}>Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="/about" onClick={scrollToTop}>About</NavLink>
                            </li>
                            <li>
                                <NavLink to="/catalogue" onClick={scrollToTop}>Download Catalogue</NavLink>
                            </li>
                            <li>
                                <NavLink to="/export" onClick={scrollToTop}>Export</NavLink>
                            </li>
                            <li>
                                <NavLink to="/contact" onClick={scrollToTop}>Contact Us</NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className='quick-links'>
                        <label>Enquiries</label>
                        <ul>
                            <li className='enquiry_contact'>
                                <p>Domestic Enquiry</p>
                                <div className='enquiry'>
                                    <p className='email'>
                                        <span className='social-icon'>
                                            <FaEnvelope />
                                        </span>
                                        <a href="mailto:info@vincentceramica.com">info@vincentceramica.com</a>
                                    </p>
                                    <p className='mobile'>
                                        <span className='social-icon'>
                                            <FaPhoneAlt />
                                        </span>
                                        <a href="tel:9711741117">+91-9711741117 ( Anand Patel )</a>
                                    </p>
                                </div>
                            </li>
                            {/* <li>
                                <NavLink to="/terms-conditions">Terms & Conditions</NavLink>
                            </li>
                            <li>
                                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                            </li> */}
                        </ul>
                        <ul>
                            <li className='enquiry_contact'>
                                <p>Export Enquiry</p>
                                <div className='enquiry'>
                                    <p className='email'>
                                        <span className='social-icon'>
                                            <FaEnvelope />
                                        </span>
                                        <a href="mailto:info@vincentceramica.com">info@vincentceramica.com</a>
                                    </p>
                                    <p className='mobile'>
                                        <span className='social-icon'>
                                            <FaPhoneAlt />
                                        </span>
                                        <a href="tel:8591976532">+91-8591976532 ( Abhinav Choudhary )</a>
                                    </p>
                                </div>
                                {/* <NavLink to="/about">About Company</NavLink> */}
                            </li>
                            {/* <li>
                                <NavLink to="/terms-conditions">Terms & Conditions</NavLink>
                            </li>
                            <li>
                                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                            </li> */}
                        </ul>
                    </div>
                    <div className='follow-us'>
                        <label>Follow Us</label>
                        <ul>
                            <li>
                                <a href='' target="_blank" style={{ display: 'none' }}>
                                    <span className='social-icon'>
                                        <FaFacebookF />
                                    </span>
                                    Facebook
                                </a>
                            </li>
                            <li>
                                <a href='https://www.linkedin.com/company/vincent-ceramica/' target="_blank">
                                    <span className='social-icon'>
                                        <FaLinkedinIn />
                                    </span>
                                    LinkedIn
                                </a>
                            </li>
                            <li>
                                <a href='https://instagram.com/vincentceramica?igshid=MWI4MTIyMDE=' target="_blank">
                                    <span className='social-icon'>
                                        <FaInstagram />
                                    </span>
                                    Instagram
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='copyright-container'>
                <div className='copyright-wrapper'>
                    <p>&copy; {todaysDate}. All Rights Reserved by Vincent Ceramica</p>
                    <p>Designed & Developed By: <a href='http://www.codinghabbit.com/' target="_blank" rel="noreferrer">Coding Habbit</a></p>
                </div>
            </div>
        </div>
    </>
}

export default Footer;