import "../about/about.scss";
import foregroundImage from "../../assets/about2.jpg";
import aboutUsBgImage from "../../assets/about-us-bg.jpg";
import Navbar from "../../shared/components/top-navigation/top-navigation";
import Footer from "../../shared/components/footer/footer";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import Header from "../../shared/components/header/header";
import VincentDeals from "../../shared/components/vincent-deals/vincent-deals";

const About = () => {
    useDocumentTitle("About");
    return <>
        <Navbar />
        <div className="about-container">
            <Header description="Why choose Vincent Ceramica" imageUrl={aboutUsBgImage} title="About Us" />
            <VincentDeals />
            <div className="about-us-details">
                <div className="about-mission-vision">
                    <div className="our-mission-vision">
                        <h3>Our Mission</h3>
                        <p className="mission-content">
                            Our mission is to ensure every product we sell is of the highest quality, meeting or exceeding customer expectations.
                            We are dedicated to offering our customers the best possible value for their money with competitive pricing,
                            eliminating any worries about hidden costs or fees.
                        </p>
                    </div>
                    <div className="our-mission-vision">
                        <h3>Our vision</h3>
                        <p className="mission-content">
                            Our vision is to provide you with the highest-quality ceramic tiles at the best possible price. We meticulously manage
                            our inventory to maintain consistency in color, shape, and size. If we detect any shortages or discontinued products, we
                            will promptly inform you and swiftly remove them from stock.
                        </p>
                    </div>
                </div>
                <div className="images-wrapper">
                    <img src={aboutUsBgImage} alt="" className="bg-image" />
                    <div className="fg-image">
                        <img src={foregroundImage} alt="" />
                    </div>
                </div>
                <div className="images">
                    <img src={aboutUsBgImage} alt="" />
                    <img src={foregroundImage} alt="" className="fg-image" />
                </div>
            </div>
        </div>
        <Footer />
    </>
}

export default About;