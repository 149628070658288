import './header.scss';

const Header = (props: { description: string; imageUrl: string; title: string; }) => {

    const { description, imageUrl, title} = props;

    return <>
        <div className="container" style={{ backgroundImage: `url(${imageUrl})`}}>
            <div className="wrapper">
                <div className="header_detail">
                    <span className="description">{description}</span>
                    <p className="title"><span>Vincent</span> <span>/</span> <span>{title}</span></p>
                </div>
            </div>
        </div>
    </>
}

export default Header;