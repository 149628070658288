import style from "../send-message/send-message.module.scss";
import contactUsHomePage from "../../assets/contactUsHomePage.jpg";

const SendMessage = () => {

    const onSubmit = (e: any) => {
        e.preventDefault();
    };

    return <>
        <div className={style.sendMsg_container}>
            <h3 className={style.title}>Lets Create Something Great Together</h3>
            <div className={style.sendMsg_wrapper}>
                <div className={style.image_container}>
                    {/* <img src={contactUsHomePage} alt="" /> */}
                </div>
                <div className={style.contact_us_form}>
                    <div className={style.heading}>
                        <h3>Contact Us</h3>
                    </div>
                    <form className={style.user_form} onSubmit={onSubmit}>
                        <input type="text" className={style.input_field} placeholder="Full Name" />
                        <input type="number" className={style.input_field} placeholder="Contact Number" />
                        <input type="text" className={style.input_field} placeholder="Email Address" />
                        <textarea className={style.input_field} rows={5} placeholder="Message"></textarea>
                        <button className={style.button} type="submit">Send Message</button>
                    </form>
                </div>
            </div>
        </div>
    </>
}
export default SendMessage;