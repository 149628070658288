import React, { useState, useEffect } from 'react';
import img1 from '../../../assets/about1.jpg';
import img2 from '../../../assets/about2.jpg';
import img3 from '../../../assets/about3.jpg';
import style from '../carousel/carousel.module.scss';
import { FaChevronCircleLeft, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const ImageSlider = ({ images }: any) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (images && images.length > 0) {
            setCurrentIndex(0);
        }
    }, [images]);

    const handleNext = () => {
        if (images && images.length > 0) {
            setCurrentIndex((currentIndex + 1) % images.length);
        }
    };

    const handlePrev = () => {
        if (images && images.length > 0) {
            setCurrentIndex((currentIndex - 1 + images.length) % images.length);
        }
    };

    const handleSlide = (index: number) => {
        if (images && images.length > 0) {
            setCurrentIndex(index);
        }
    };

    // Automatically slide to the next image every 5 seconds
    useEffect(() => {
        const intervalId = setInterval(() => {
            handleNext();
        }, 5000);
        return () => clearInterval(intervalId);
    }, [handleNext]);

    return (
        <div className={style.image_slider}>
            <div className={style.slider_bg}></div>
            <div className={style.slider}>
                {images && images.map((image: any, index: number) => (
                    <div
                        key={index}
                        className={`${style.slide} ${index === currentIndex ? `${style.active}` : ''}`}
                    >
                        <img src={image.src} alt={image.caption} />
                        <div className={style.caption}>{image.caption}</div>
                    </div>
                ))}
                <div className={style.controls}>
                    <button onClick={handlePrev}><FaChevronLeft /></button>
                    <button onClick={handleNext}><FaChevronRight /></button>
                </div>
                <div className={style.dot_indicator}>
                    {images && images.map((image: any, index: number) => (
                        <div
                            key={index}
                            className={`${style.dot} ${index === currentIndex ? `${style.active}` : ''}`}
                            onClick={() => handleSlide(index)}
                        />
                    ))}
                </div>
            </div>

            <div className={style.preview}>
                {images && images.map((image: any, index: number) => (
                    <div
                        key={index}
                        className={`${style.preview_slide} ${index === currentIndex ? `${style.active}` : ''}`}
                        onClick={() => handleSlide(index)}
                    >
                        <img src={image.src} alt={image.caption} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageSlider;