import { Link } from "react-router-dom"

const PageNotFound = () => {

    return <>
        <div>
            <h1>Oops! You seem to be lost.</h1>
            <p>Here are some helpful links:</p>
            <Link to='/'>Home</Link>
            <Link to='/about'>About</Link>
            <Link to='/export'>Export</Link>
            <Link to='/catalogue'>Catalogue</Link>
            <Link to='/contact'>Contact</Link>
        </div>
    </>
}

export default PageNotFound;