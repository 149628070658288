import useCountUp from '../../hooks/useCountUp';
import '../statistics/statistics.scss';

const Statistics = () => {
    const dealsCount = useCountUp(600, 4);
    const sizesCount = useCountUp(15, 4);
    const surfacesCount = useCountUp(30, 4);
    const unitsCount = useCountUp(10, 4);

    return (
        <div className="statistics-container">
            <div className="statistics-wrapper">
                <h3 className="title">Our Achievements at a Glance</h3>
                <p>We're proud of our accomplishments. We're dedicated to continuing our pursuit of innovation and customer satisfaction</p>
                <div className="content">
                    <div className="statistics">
                        <p className="statistics-name">Deals in India</p>
                        <p className="count">{dealsCount}+</p>
                    </div>
                    <div className="statistics">
                        <p className="statistics-name">Available Sizes</p>
                        <p className="count">{sizesCount}+</p>
                    </div>
                    <div className="statistics">
                        <p className="statistics-name">Innovative surfaces</p>
                        <p className="count">{surfacesCount}+</p>
                    </div>
                    <div className="statistics">
                        <p className="statistics-name">Manufacturing units</p>
                        <p className="count">{unitsCount}+</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Statistics;