import "../contact-us/contact-us.scss";
import Navbar from "../../shared/components/top-navigation/top-navigation";
import Footer from "../../shared/components/footer/footer";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import contactBg from "../../assets/contactUs/contact1.jpg";
import Header from "../../shared/components/header/header";
import { FaEnvelope, FaLocationArrow, FaPhoneAlt } from "react-icons/fa";

const Contact = () => {

    useDocumentTitle("Contact");

    const onSubmit = (e: any) => {
        e.preventDefault();
    };

    return <>
        <Navbar />
        <div className="contact-container">
            <div className="contact-wrapper">
                <Header description="Let's Connect" imageUrl={contactBg} title="contact us" />
                <div className="contact-us-content">
                    <div className="contact-details">
                        <div className="heading">
                            <h3>Contact Details</h3>
                            <p>You can also reach out to us by phone
                                or email regarding business</p>
                        </div>
                        <div className="contact-cards">
                            <div className="contact-icon">
                                <FaPhoneAlt />
                            </div>
                            <div className="contact-type-details">
                                <span className="title">Phone</span>
                                <p><a href="tel:9711741117">+91-8591976532 ( Abhinav Choudhary )</a></p>
                            </div>
                        </div>
                        <div className="contact-cards">
                            <div className="contact-icon">
                                <FaEnvelope />
                            </div>
                            <div className="contact-type-details">
                                <span className="title">Email</span>
                                <p><a href="mailto:info@vincentceramica.com">info@vincentceramica.com</a></p>
                            </div>
                        </div>
                        <div className="contact-cards">
                            <div className="contact-icon">
                                <FaLocationArrow />
                            </div>
                            <div className="contact-type-details">
                                <span className="title">Address</span>
                                <address>
                                    S-37, Siromani Business Center, <br />
                                    B/H Real Plaza, Morbi - 2, Gujarat
                                </address>
                            </div>
                        </div>
                    </div>
                    <div className="contact-us-form">
                        <div className="heading">
                            <h3>Send a message</h3>
                            <p>Please fill the below form for your queries, our team will contact you as soon as possible</p>
                        </div>
                        <form className="user-form" onSubmit={onSubmit}>
                            <input type="text" className="input-field" placeholder="Full Name" />
                            <input type="number" className="input-field" placeholder="Contact Number" />
                            <input type="text" className="input-field" placeholder="Email Address" />
                            <textarea className="input-field" rows={5} placeholder="Message"></textarea>
                            <button className="button" type="submit">send message</button>
                        </form>
                    </div>
                </div>
                <div className="location">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58840.715666752076!2d70.84851788068472!3d22.819327624295735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39598dfd1ef36953%3A0x7e635443e0d30222!2sSiromany%20Business%20Center!5e0!3m2!1sen!2sin!4v1673167860191!5m2!1sen!2sin"
                        width="600" height="450"
                        style={{ border: 0, width: '100%' }}
                        loading="lazy"
                        title="test"></iframe>
                </div>
            </div>
        </div>
        <Footer />
    </>
}
export default Contact;