import { getMaxListeners } from "process";
import Footer from "../../shared/components/footer/footer";
import Navbar from "../../shared/components/top-navigation/top-navigation";
import "./enquiry.scss";

const Enquiry = () => {
    const data = [
        { serialNo: 1, name: "Anom", email: "abcd@getMaxListeners.com", phone: "7687654345", msg: "Hi Good Evening Hi Good EveningHi Good EveningHi Good Eveningvv" },
        { serialNo: 2, name: "Megha", email: "abcd@getMaxListeners.com", phone: "7687654345", msg: "Hi Good EveningHi Good Evening Hi Good EveningHi Good EveningHi Good EveningvvHi Good Evening Hi Good EveningHi Good EveningHi Good Eveningvv" },
        { serialNo: 3, name: "Subham", email: "abcd@getMaxListeners.com", phone: "7687654345", msg: "Hi Good Evening" },
    ]

    return <>
        <Navbar/>
        <div className="enquiry-container">
            <div className="enquiry-wrapper">
                <h1 className="heading">Enquiry Table</h1>
                <table className="table">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Message</th>
                        </tr>
                    </thead>
                    {data.map((val, index) => {
                        return (
                            <tbody key={index}>
                                <tr>
                                    <td data-label="S.No">{val.serialNo}</td>
                                    <td data-label="Name">{val.name}</td>
                                    <td data-label="Email">{val.email}</td>
                                    <td data-label="Phone">{val.phone}</td>
                                    <td data-label="Message">{val.msg}</td>
                                </tr>
                            </tbody>
                        )
                    })}
                </table>
            </div>

        </div>
        <Footer/>
    </>
}

export default Enquiry;