import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import "../side-menu/sidemenu.scss";
import logo from "../../../assets/logo/vincentWhiteLogo.png";
import { NavLink } from "react-router-dom";
import { FaEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn, FaPhoneAlt, FaPhoneVolume } from 'react-icons/fa';

const SideMenu = forwardRef((props, ref: any) => {

    const [isMobile, setIsMobile] = useState(false);

    useImperativeHandle(ref, () => ({

        openNav() {
            console.log('call')
            document.getElementById("mySidenav")!.style.width = "100%";
            document.getElementById("mySidenav")!.style.padding = "40px";
            document.body.style.overflow = 'hidden';
        }
    }));

    const closeNav = () => {
        //@ts-ignore
        document.getElementById("mySidenav").style.width = "0";
        document.getElementById("mySidenav")!.style.padding = "unset";
        document.body.style.overflow = 'unset';
    }

    //choose the screen size 
    const handleResize = () => {
        if (window.innerWidth < 769) {
            setIsMobile(true)
        } else {
            closeNav();
            setIsMobile(false)
        }
    }

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    return <>
        <div id="mySidenav" className="sidenav">
            <div className="logo-container">
                <img src={logo} alt="logo"/>
            </div>
            <button className="closebtn" onClick={closeNav}>&times;</button>
            <div className="nav_items">
                    <div onClick={closeNav}>
                        <NavLink to="/">Home</NavLink>
                    </div>
                    <div onClick={closeNav}>
                        <NavLink to="/about">About</NavLink>
                    </div>
                    <div onClick={closeNav}>
                        <NavLink to="/catalogue">Catalogue</NavLink>
                    </div>
                    <div onClick={closeNav}>
                        <NavLink to="/export">Export</NavLink>
                    </div>
                    <div onClick={closeNav}>
                        <NavLink to="/contact">Contact Us</NavLink>
                    </div>
            </div>
            <div className='follow-us'>
                <label>Follow Us</label>
                <ul>
                    <li>
                        <a>
                            <span style={{display: 'none'}} className='social-icon'>
                                <FaFacebookF />
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href='https://www.linkedin.com/company/vincent-ceramica/'  target="_blank">
                            <span className='social-icon'>
                                <FaLinkedinIn />
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href='https://instagram.com/vincentceramica?igshid=MWI4MTIyMDE='  target="_blank">
                            <span className='social-icon'>
                                <FaInstagram />
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </>
});

export default SideMenu;